import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, skipWhile, switchMap, take } from 'rxjs/operators';
// import { AppService } from './app.service';
// import { AuthserviceService } from './authservice.service';
// import { UsersService } from './users.service';
import { DataService } from './data.service';
import { HrDataService } from './hr-data.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private dataService: DataService, private hrData: HrDataService, public router: Router,
    ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const jwtToken = this.dataService.getCookie("jwtAccessToken");

    if (request.url.includes('/regenerateJwtToken') || request.url.includes('/validateLogin')) {
        return next.handle(request);  // Skip login request
    }

    console.log('Inside intercept');
    let userID = this.dataService.getCookie("authId");
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${jwtToken + `$` + userID}`
      }
    });

    return next.handle(request).pipe(catchError((error) => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        return this.handle401Error(request, next);
      }
      return throwError(error);
    }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    const userId = this.dataService.getCookie("authId");  
    const refreshToken = this.dataService.getCookie("refreshToken");
    
      if (refreshToken) {
        let tokenData = {
          'userId': userId,
          'refreshToken': refreshToken
        }
        return this.dataService.generateJwtToken(tokenData).pipe(
          switchMap((token: any) => {
            const tokenData = token;
            // console.log('New Token : ' + tokenData + ' : new : ' + tokenData.jwtToken)
            document.cookie = "jwtAccessToken=" + tokenData.jwtToken +";secure";
            return next.handle(this.addTokenHeader(request));
          }),
          catchError((err) => {

            this.logout();
            return throwError(err);
          })
        );
      } else {
        this.logout();
        return throwError(new Error);
      }
  }

  private addTokenHeader(request: HttpRequest<any>) {
    const userId = this.dataService.getCookie("authId");  
    const jwtToken = this.dataService.getCookie("jwtAccessToken");
    console.log('Inside add Token');
    return request.clone({
        setHeaders: {
          Authorization: `Bearer ${jwtToken + `$` + userId}`
        }
      });
  }

  logout() {
    window.sessionStorage.clear();
    this.hrData.setReadFlag(false);    
    localStorage.setItem('authUser', 'noUser'); 
    window.localStorage.clear();    
    this.hrData.setHrFlag(false);
    this.router.navigate(['/login']);
  }

}
