import { Injectable, Directive  } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Headers, RequestOptions } from '@angular/http';
import { SessionStorageService } from 'angular-web-storage';
import { AlertController } from '@ionic/angular';
import { AppSettings } from './appSetting.config';
import { FileSelectDirective, FileDropDirective, FileUploader } from 'ng2-file-upload/ng2-file-upload';
import { subscribeOn } from 'rxjs/operators';
import { Router, RouteConfigLoadEnd } from '@angular/router';
import {HrDataService} from './hr-data.service';
import { ToastController } from '@ionic/angular';
import { UserIdleService } from 'angular-user-idle';

const emp_id = {};
@Injectable()
export class DataService {
  dataStatus: string = ''; 
  reviewStatus: string = '';      
  private dataDetail = new BehaviorSubject(this.dataStatus);
  currentDataStatus = this.dataDetail.asObservable(); 
  private messageSource = new BehaviorSubject('Default');
  currentMessage = this.messageSource.asObservable();
 
  // apiURL: string = 'http://10.2.108.65:4000/api/onboards';
  jsonData : any;
  postData: any;
  savePostData: any;
  empFechedDataById : any;
  savejsonData: any;
  empid: string;
  uploaddata: any =[];
  tenthTrue: boolean = false;
  gradTrue: boolean = false;
  constructor(private userIdle: UserIdleService, private hrDataSer: HrDataService, private toastCtrl: ToastController, private router:Router, private httpClient: HttpClient, public http: Http, public session: SessionStorageService, private alertController: AlertController) { }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }
  setEmpID(id){ 
    // console.log('setEmpID',id);
    emp_id['prop'] = id; 
  }

  getEmpID(flag){
    
    if(emp_id['prop'] == undefined){
      emp_id['prop'] = this.session.get('empId');
    }
    const URL =  AppSettings.UPLOADCERTIFICATE_URL+'?_id='+ emp_id['prop']+'&flag='+flag;
    return URL;
  }

  //post data on save  
  setEmployeedata(status, id) {
    if(this.session.get('employeeDetail') == null) {
      this.session.set('employeeDetail', {});
    }    
    if( this.session.get('contactDetail') == null) {      
      let contactDetailData = { 
        contactDetail:{     
          currentAddress: null,   
          currentPhone: null,
          currentResidingSince: null,
          emailId : this.session.get('empDataByHR').empEmail || null,
          emergencyContactno: null,
          emergencyPersonName: null,
          officialCorrespondence : null,
          presentAddress: null,
          presentAltPhone: null,
          presentPhone: null,
          residingSince: null,  
          emergencyMobile : null
        }
      };   
      this.session.set('contactDetail', contactDetailData);
    }   
    this.jsonData = Object.assign(this.session.get('employeeDetail') , this.session.get('contactDetail'),
    this.session.get('educationDetails'), this.session.get('experienceDetails'),this.session.get('insuranceDetail'),this.session.get('formf'),this.session.get('confirmationDetail'),this.session.get('appDetail'));
    // if((this.jsonData !== undefined) || (this.jsonData !== null)){      
      this.postData = {
        "_id": id,
        empDataByHR: this.session.get('empDataByHR'),
        employeeDetails: this.jsonData, 
        status: status,
        updatedTime: new Date(), 
        hrComments: this.session.get('confirmationDetail') ? this.session.get('confirmationDetail').hrComments : null,  
        hrReviewStatus: this.reviewStatus ? this.reviewStatus : null,
        totalExperience: this.session.get('experienceDetails') ? this.session.get('experienceDetails').totalExperience : null   
      };  
      this.hrDataSer.changeAuthUser('authorizedUser');    
      localStorage.setItem('authUser', 'authorizedUser');
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      // const options = new RequestOptions({ headers: headers });
      
      return this.httpClient.post(AppSettings.POST_EMP_DATA, this.postData, {headers});
      // .subscribe(  
      //   data => {
      //     this.dataStatus = data.statusText;  
      //     // this.thankyouAlert(this.dataStatus);
      //     window.sessionStorage.clear();     
      //   },
      //   async error => {
      //     console.log('oops', error);
      //     const alert = await this.alertController.create({
      //       header: 'Error!',
      //       message: 'Add Details Failed. Please try after sometime.',
      //       buttons: [
      //         {
      //           text: 'Ok',
      //           handler: () => {    
      //           }
      //         }
      //       ]
      //     });
      //     await alert.present(); 
      //   });
  }
  
  async thankyouAlert(status) {
    if(status == 'OK') {
      //Form completion alert
      const alert = await this.alertController.create({
        header: 'Thanks!',
        message: 'Thank you for completing the formality.',
        buttons: [
          {
            text: 'Ok',
            handler: () => {
              // console.log('Confirm Okay');
            }
          }
        ]
      });
      await alert.present();
    }
  }  
  
  setDataStatus(status){
    this.dataDetail = status;
  }
  getDataStatus(){
    
    return this.dataDetail;
  }

  setHrReviewStatus(status){
    this.reviewStatus = status;
  }

  getHrReviewStatus(){
    return this.reviewStatus;
  }
  //On employee home page by Hr
  getEmployeedata() {        
    return this.httpClient.get(AppSettings.GET_EMP_DATA);
  }

  getAddressStates() {
    return this.httpClient.get(AppSettings.GET_STATES);
  }

  editData(id) {   
    if(this.session.get('employeeDetail') == null) {
      this.session.set('employeeDetail', {});
    }     
    // if((this.session.get('empDataByHR').empEmail !== this.session.get('contactDetail').emailId) && this.session.get('contactDetail') == null) {      
    //   let contactDetailData = { 
    //     contactDetail:{     
    //       currentAddress: this.session.get('contactDetail').currentAddress || null ,   
    //       currentPhone: this.session.get('contactDetail').currentPhone || null,
    //       currentResidingSince: this.session.get('contactDetail').currentResidingSince || null,
    //       emailId : this.session.get('empDataByHR').empEmail || null,
    //       emergencyContactno: this.session.get('contactDetail').emergencyContactno || null,
    //       emergencyPersonName: this.session.get('contactDetail').emergencyPersonName || null,
    //       officialCorrespondence : this.session.get('contactDetail').officialCorrespondence || null,
    //       presentAddress: this.session.get('contactDetail').presentAddress || null,
    //       presentAltPhone: this.session.get('contactDetail').presentAltPhone || null,
    //       presentPhone: this.session.get('contactDetail').presentPhone || null,
    //       residingSince: this.session.get('contactDetail').residingSince || null,  
    //       emergencyMobile : this.session.get('contactDetail').emergencyMobile || null
    //     }
    //   };   
    //   this.session.set('contactDetail', contactDetailData);
    // }   
    localStorage.setItem('authUser', 'authorizedUser');
    this.hrDataSer.changeAuthUser('authorizedUser');
    this.jsonData = Object.assign(this.session.get('employeeDetail') , this.session.get('contactDetail'),
    this.session.get('educationDetails'), this.session.get('experienceDetails'),this.session.get('insuranceDetail'),this.session.get('formf'),this.session.get('confirmationDetail'),this.session.get('appDetail'));
    
    this.postData = {
      "_id": id,
      empDataByHR: this.session.get('empDataByHR'),
      employeeDetails: this.jsonData, 
      status: this.dataDetail,
      hrComments: this.session.get('confirmationDetail') ? this.session.get('confirmationDetail').hrComments : null,  
      updatedTime: new Date(), 
      hrReviewStatus: this.reviewStatus,
      totalExperience: this.session.get('experienceDetails') ? this.session.get('experienceDetails').totalExperience : null       
    };   
    return this.httpClient.put(AppSettings.PUT_EMP_DATA+'?id=' + id, this.postData);
    // .subscribe(data=> console.log(data),
    // error=> console.log(error));
  }

  //Save for each page
  editDataPerPage(pageDetail) {
    this.empFechedDataById = this.empFechedDataById ? this.empFechedDataById : this.getLoginData(this.session.get('empDataByHR').empEmail);   
    if(this.empFechedDataById ) {
      new Promise(resolve => {
        // console.log('empFechedDataById', this.empFechedDataById);
        this.empFechedDataById.subscribe(data => {  
          resolve(data.json());
        })
      }).then((value) => {
        if(this.session.get('employeeDetail') == null) {
          this.session.set('employeeDetail', {});
        }     
        // if((this.session.get('empDataByHR').empEmail !== this.session.get('contactDetail').emailId) && this.session.get('contactDetail') == null) {      
        //   let contactDetailData = { 
        //     contactDetail:{     
        //       currentAddress: this.session.get('contactDetail').currentAddress || null ,   
        //       currentPhone: this.session.get('contactDetail').currentPhone || null,
        //       currentResidingSince: this.session.get('contactDetail').currentResidingSince || null,
        //       emailId : this.session.get('empDataByHR').empEmail || null,
        //       emergencyContactno: this.session.get('contactDetail').emergencyContactno || null,
        //       emergencyPersonName: this.session.get('contactDetail').emergencyPersonName || null,
        //       officialCorrespondence : this.session.get('contactDetail').officialCorrespondence || null,
        //       presentAddress: this.session.get('contactDetail').presentAddress || null,
        //       presentAltPhone: this.session.get('contactDetail').presentAltPhone || null,
        //       presentPhone: this.session.get('contactDetail').presentPhone || null,
        //       residingSince: this.session.get('contactDetail').residingSince || null,  
        //       emergencyMobile : this.session.get('contactDetail').emergencyMobile || null
        //     }
        //   };   
        //   this.session.set('contactDetail', contactDetailData);
        // } 
        this.savejsonData = Object.assign(pageDetail !== 'employeeDetail' ? this.session.get('employeeDetail') : this.session.get(pageDetail),
        pageDetail !== 'contactDetail' ? this.session.get('contactDetail') : this.session.get(pageDetail),
        pageDetail !== 'educationDetails' ? this.session.get('educationDetails') : this.session.get(pageDetail),
        pageDetail !== 'experienceDetails' ? this.session.get('experienceDetails') : this.session.get(pageDetail),
        pageDetail !== 'insuranceDetail' ? this.session.get('insuranceDetail') : this.session.get(pageDetail),
        pageDetail !== 'formf' ? this.session.get('formf') : this.session.get(pageDetail),
        pageDetail !== 'confirmationDetail' ? this.session.get('confirmationDetail') : this.session.get(pageDetail)      
        );
        
        this.savePostData = {
          "_id": value[0]._id,
          empDataByHR: value[0].empDataByHR,
          employeeDetails: this.savejsonData, 
          status: value[0].status,
          hrComments: value[0].hrComments || null,  
          updatedTime: new Date(), 
          hrReviewStatus: value[0].hrReviewStatus || null,
          totalExperience: this.session.get('experienceDetails') ? this.session.get('experienceDetails').totalExperience : null //value[0].totalExperience || null  //need change     
        }; 
        this.empid = value[0]._id;
  
        return this.httpClient.put(AppSettings.PUT_EMP_DATA+'?id=' + this.empid, this.savePostData).subscribe(data => {
          // console.log('data', data);
        },error => {} );
      });  
    } else   
    
    if(this.hrDataSer.getHrFlag()){
      // this.hrDataSer.empDataSelected.subscribe(selecteddata => { console.log('selecteddata', selecteddata) })
      new Promise(resolve => {
        this.hrDataSer.empDataSelected.subscribe(selecteddata => {   
          // console.log('selecteddata', selecteddata); 
          let selectedHrData = JSON.parse(JSON.stringify(selecteddata));      
          if(this.session.get('employeeDetail') == null) {
            this.session.set('employeeDetail', {});
          } 
          // console.log('this.session.get(contactDetail)', this.session.get('contactDetail'));
          // if((this.session.get('empDataByHR').empEmail !== (this.session.get('contactDetail') ? this.session.get('contactDetail').emailId 
          // : this.session.get('empDataByHR').empEmail)) && this.session.get('contactDetail') == null) {      
          //   let contactDetailData = { 
          //     contactDetail:{     
          //       currentAddress: this.session.get('contactDetail').currentAddress || null ,   
          //       currentPhone: this.session.get('contactDetail').currentPhone || null,
          //       currentResidingSince: this.session.get('contactDetail').currentResidingSince || null,
          //       emailId : this.session.get('empDataByHR').empEmail || null,
          //       emergencyContactno: this.session.get('contactDetail').emergencyContactno || null,
          //       emergencyPersonName: this.session.get('contactDetail').emergencyPersonName || null,
          //       officialCorrespondence : this.session.get('contactDetail').officialCorrespondence || null,
          //       presentAddress: this.session.get('contactDetail').presentAddress || null,
          //       presentAltPhone: this.session.get('contactDetail').presentAltPhone || null,
          //       presentPhone: this.session.get('contactDetail').presentPhone || null,
          //       residingSince: this.session.get('contactDetail').residingSince || null,  
          //       emergencyMobile : this.session.get('contactDetail').emergencyMobile || null
          //     }
          //   };   
          //   this.session.set('contactDetail', contactDetailData);
          // }     
          // console.log(pageDetail !== 'contactDetail' ? this.session.get('contactDetail') : this.session.get(pageDetail));
          this.savejsonData = Object.assign(pageDetail !== 'employeeDetail' ? this.session.get('employeeDetail') : this.session.get(pageDetail),
          pageDetail !== 'contactDetail' ? this.session.get('contactDetail') : this.session.get(pageDetail),
          pageDetail !== 'educationDetails' ? this.session.get('educationDetails') : this.session.get(pageDetail),
          pageDetail !== 'experienceDetails' ? this.session.get('experienceDetails') : this.session.get(pageDetail),
          pageDetail !== 'insuranceDetail' ? this.session.get('insuranceDetail') : this.session.get(pageDetail),
          pageDetail !== 'formf' ? this.session.get('formf') : this.session.get(pageDetail),
          pageDetail !== 'confirmationDetail' ? this.session.get('confirmationDetail') : this.session.get(pageDetail)      
          );
          
          this.savePostData = {
            "_id": selectedHrData._id,
            empDataByHR: selectedHrData.empDataByHR,
            employeeDetails: this.savejsonData, 
            status: selectedHrData.status,
            hrComments: selectedHrData.hrComments || null,  
            updatedTime: new Date(), 
            hrReviewStatus: selectedHrData.hrReviewStatus || null,
            totalExperience: this.session.get('experienceDetails') ? this.session.get('experienceDetails').totalExperience : null  //need change     
          }; 
          this.empid = selectedHrData._id;
    
          return this.httpClient.put(AppSettings.PUT_EMP_DATA+'?id=' + this.empid, this.savePostData).subscribe(data => {
            // console.log('data', data);
          },error => {} );
        })
      });  
    }
  }

  // Get data by Email id
  getLoginData(email) {
    this.empFechedDataById = this.httpClient.get(AppSettings.LOGIN_EMP+'?email=' + email);
    // this.getDataTillSave();
    return this.empFechedDataById;
  }

  getDataTillSave() {
    new Promise(resolve => {
      this.empFechedDataById.subscribe(data => {        
        resolve(data.json());
      })
    }).then((value) => {
       // emergencyContactno: null
      // emergencyMobile: null
      // emergencyPersonName: null
      // officialCorrespondence: null
      // presentPhone: null
      // residingSince: null
      this.readUploadData(value[0]._id).subscribe((data) => {
        // this.uploaddata = data.text();
        if(data) {
          data.forEach(element => {
            if((element.match(/.zip/g) == ".zip" || element.match(/.ZIP/g) == ".ZIP" || element.match(/.rar/g) == ".rar" || element.match(/.RAR/g) == ".RAR") 
          || (element.includes(".pdf") || element.includes(".PDF")) && (element.includes('GeneralUp-'))
          || (element.includes(".pdf") || element.includes(".PDF")) && (element.includes('ExperienceUp-'))
          || (element.includes(".pdf") || element.includes(".PDF")) && (element.includes('EducationUp-'))
          || (element.includes(".pdf") || element.includes(".PDF")) && (element.includes('PAN-'))){
              
              this.uploaddata.push(element);
              // this.globalVar.changeUplodedZipPresent(true);
            }else {
              // this.globalVar.changeUplodedZipPresent(false);
            }
          }); 
        }
      
        if(value[0].status == 'In Progress'){
          
          for(var i=0; i<value[0].employeeDetails.educationDetail.length+1; i++){
            
            if(value[0].employeeDetails.educationDetail[i] ? (value[0].employeeDetails.educationDetail[i].degree == '10th') && (value[0].employeeDetails.educationDetail[i].eduType=='schools') : false){              
              this.tenthTrue = true;         
            }
            if(value[0].employeeDetails.educationDetail[i] ? value[0].employeeDetails.educationDetail[i].eduType == 'graduation' : false ){
              this.gradTrue = true;
            }

          }
          if(value[0].employeeDetails.empPersonalData == undefined || (!value[0].empDataByHR.empFName) || (!value[0].employeeDetails.empPersonalData.DOB) 
          || (!value[0].empDataByHR.empLName) || (!value[0].employeeDetails.empPersonalData.PAN)){
            this.router.navigate(['/home']);
          } else 
          if((value[0].employeeDetails.contactDetail.emergencyPersonName == null) || !(value[0].employeeDetails.contactDetail.permanentResidingSince) || !(value[0].employeeDetails.contactDetail.permanentPhone) || 
          !(value[0].employeeDetails.contactDetail.permanentAddressFlatNo) || !(value[0].employeeDetails.contactDetail.permanentAddressPin) || (value[0].employeeDetails.contactDetail.emergencyMobile == null)
          || (!value[0].employeeDetails.contactDetail.permanentAddressStreet) || (!value[0].employeeDetails.contactDetail.permanentAddressArea) || 
         (!value[0].employeeDetails.contactDetail.permanentAddressDistrict) || (!value[0].employeeDetails.contactDetail.permanentAddressState)){            
            this.router.navigate(['/contactdetail']);
          } else 
          if(value[0].employeeDetails.educationDetail.length == 0 || this.tenthTrue == false  || this.gradTrue == false){
            this.router.navigate(['/educationdetail']);
          } else 
          if(value[0].employeeDetails.experienceDetail.length == 0){
            this.router.navigate(['/experiencedetail']);
          } else 
          if(this.uploaddata.length == 0){
            this.router.navigate(['/upload-document']);
          // }else 
          // if((value[0].employeeDetails.insuranceDetail == undefined) || (+Number(this.session.get('insuranceDetail').insuranceDetail.nomineeShare1) + +Number(this.session.get('insuranceDetail').insuranceDetail.nomineeShare2) + +Number(this.session.get('insuranceDetail').insuranceDetail.nomineeShare3) !== 100)){
          //   this.router.navigate(['/insuranceform']);
          // } else 
          // if((value[0].employeeDetails.formf == undefined) || (+Number(this.session.get('formf').formf.nomineeShare1) + +Number(this.session.get('formf').formf.nomineeShare2) + +Number(this.session.get('formf').formf.nomineeShare3) !== 100)){
          //   this.router.navigate(['/form-f']);
          // }else
          }else 
          if((value[0].employeeDetails.insuranceDetail == undefined) || (+Number(value[0].employeeDetails.insuranceDetail.nomineeShare1) + +Number(value[0].employeeDetails.insuranceDetail.nomineeShare2) + +Number(value[0].employeeDetails.insuranceDetail.nomineeShare3) !== 100)){
            this.router.navigate(['/insuranceform']);
          } else 
          if((value[0].employeeDetails.formf == undefined) || (+Number(value[0].employeeDetails.formf.nomineeShare1) + +Number(value[0].employeeDetails.formf.nomineeShare2) + +Number(value[0].employeeDetails.formf.nomineeShare3) !== 100)){
            this.router.navigate(['/form-f']);
          }else
          {
            this.router.navigate(['/confirmationdetail']); 
          }
          
        }
      });
    })
  }

  getDataByID() {
    // console.log('getDataByID', this.empFechedDataById);
    if(this.empFechedDataById == undefined){
      // this.router.navigate(['/login']);
    }else
    return this.empFechedDataById;
  }

  generateJwtToken(tokenData: any): Observable<any> {
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });    
    // const options = new RequestOptions({ headers: headers });
    return this.httpClient.post(AppSettings.REGENERATE_JWT_TOKEN, tokenData);
  }

  getHRDataBylocation(location) {
    return this.httpClient.get(AppSettings.HRDATA_BYLOC+'?loc=' +location);
  }

  readUploadData(userid) {
    return this.httpClient.get<any[]>(AppSettings.GET_UPLOADDATA+'?folder='+ userid);
  }

  deleteUpload(id, fileName){   
    let delDAta = {
      "id" : id,
      "filename" : fileName
    }
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    // const options = new RequestOptions({ headers: headers });
    return this.httpClient.post(AppSettings.POST_DEL_UPLOAD, delDAta,{headers})
  }

  setSesstiontimeout(){
    //Start watching for user inactivity.
    this.userIdle.startWatching();
    
    // Start watching when user idle is starting.//alerrt
    this.userIdle.onTimerStart().subscribe(count => {});
    
    // Start watch when time is up.//logout
    this.userIdle.onTimeout().subscribe(async () =>{ 
      const alert = await this.alertController.create({
        header: 'Info!',
        message: 'Do you want to countinue the session?',
        buttons: [
          {
            text: 'Yes',
            handler: () => { 
              // this.startWatching();
              this.restart();
            }
          },
          {
            text: 'No',
            handler: () => { 
              this.router.navigate(['/candidate-logout']); 
              window.sessionStorage.clear();   
              localStorage.setItem('authUser', 'noUser'); 
              window.localStorage.clear(); 
            }
          }
        ]
      });
      await alert.present();   
    });
  }
  stop() {
    this.userIdle.stopTimer();
  }
 
  stopWatching() {
    this.userIdle.stopWatching();
  }
 
  startWatching() {
    
    this.userIdle.startWatching();
  }
 
  restart() {
    this.userIdle.resetTimer();
  }

  getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
        c = ca[i].replace(/^\s+/g, '');
        if (c.indexOf(cookieName) == 0) {
            return c.substring(cookieName.length, c.length);
        }
    }
    return '';
  }
}