import { Component, Input } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SessionStorageService } from 'angular-web-storage';
import { Router } from '@angular/router';
import { DataService } from './data.service';
import {HrDataService} from './hr-data.service';
import {ActivatedRoute} from "@angular/router";
import { AlertController } from '@ionic/angular';
import { GlobalVariableService } from './global-variable.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public appPages = [
    {
      title: 'Welcome on Board',
      url: 'welcome/:email',
      icon: 'gift',
      disableItem: true
    },
    {
      title: 'Personal Details',
      url: '/home',
      icon: 'contact',
      disableItem: true
    },
    {
      title: 'Contact Details',
      url: '/contactdetail',
      icon: 'contacts',
      disableItem: true
    },
    {
      title: 'Education Details',
      url: '/educationdetail',
      icon: 'school',
      disableItem: true
    },
    {
      title: 'Experience Details',
      url: '/experiencedetail',
      icon: 'bookmarks',
      disableItem: true
    },
    {
      title: 'Upload Documents',
      url: '/upload-document',
      icon: 'document',
      disableItem: true
    },
    {
      title: 'Nomination Form',
      url: '/insuranceform',
      icon: 'md-list-box',
      disableItem: true
    },
    {
      title: 'Form F',
      url: '/form-f',
      icon: 'ios-paper',
      disableItem: true
    },
    {
      title: 'Confirmation Details',
      url: '/confirmationdetail',
      icon: 'checkmark-circle-outline',
      disableItem: true
    }
    // {
    //   title: 'Declaration Letter',
    //   url: '/declaration-letter',
    //   icon: 'checkmark-circle-outline',
    //   disableItem: true
    // }
  ];

  usename : string;
  isHr : boolean;
  email: string;
  authUser:string;
  toastMessage: string = '';
  toastMessage1: string = '';
  toastMessage2: string = '';
  toastMessage3: string= '';
  toastMessage4: string = '';
  toastMessage5: string = '';
  perAddress:string;
  empdetailsData: any;
  id: any;
  isCandidate :  boolean = false;
  removed: any = []; 
  noSignOut: boolean = false;
  upfilename: any = [];
  @Input() menuButton: boolean;
  // disableItem: false;
  constructor(
    public globalVar : GlobalVariableService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public session: SessionStorageService,
    public router: Router,
    private data: DataService,
    private hrData: HrDataService,
    private route: ActivatedRoute,
    private alertController: AlertController
  ) {
    this.initializeApp();
    // console.log('route', route);
    // this.hrData.currentAuthUser.subscribe(user => this.authUser = user);
    // console.log(this.authUser);
    // if(this.authUser!=='authorizedUser'){
    //   this.router.navigate(['/login']);
    // }
    this.hrData.isHrFlag.subscribe(value => {
    this.isHr = value;
      if(value) {
        if(this.appPages.length == 7){
          this.isCandidate = true;  
          this.appPages.splice(0,1); 
        }
        if((this.appPages.length == 7) || (this.appPages.length == 6)){
          if(this.isCandidate == false){
            this.appPages.splice(0,1); 
          } 
          // if (this.platform.is('ipad')) {   
            

          //   this.appPages.splice(5,1);         
          // } 
        }
      } 
      else {
        // if(this.appPages.length == 7){
        //   this.noSignOut = true;  
        // }
        // if (this.platform.is('ipad')) {
          
        //   let arr = this.appPages.splice(5,1);
        // } else {
        //   // this.isIpad = false;
        // }
      }      
    });  
      
  }

  isActive(p){
    if(p)
    {
      document.getElementById("menuItem").style.background = "blue";
    }  
   
  }

  empdata: any;
  selectedHrData:any;
  tenthTrue: boolean = false;
  gradTrue: boolean = false;

  async menuItemClick(e, p){  
    this.upfilename =[];     
    if((this.hrData.getHrFlag() == undefined) || (this.hrData.getHrFlag() ==false)){
     
        this.toastMessage = '';
        this.toastMessage1 = '';
        this.toastMessage2 = '';
        this.toastMessage3 = '';
        this.toastMessage4 = '';
        this.toastMessage5 = '';
		    let contactData = this.session.get('contactDetail').contactDetail;
        if(this.data.getDataByID()){

      
          this.data.getDataByID().subscribe(data=> { 
            let value= data.json();
        this.email = value[0].empDataByHR.empEmail;				   
            if(p.icon == 'gift') {
              this.router.navigate(['welcome/'+this.email]);
            }
        this.id = value[0]._id;
        this.globalVar.changeEmpDbId(this.id);
        if(value[0]){
          
          if(this.session.get('employeeDetail')){            
            this.session.set('employeeDetail', this.session.get('employeeDetail'));
          } else
          if(value[0].employeeDetails.empPersonalData) {
            let employees = { empPersonalData: value[0].employeeDetails.empPersonalData} 
            this.session.set('employeeDetail', employees);
          }
          if(this.session.get('contactDetail')){            
            this.session.set('contactDetail', this.session.get('contactDetail'));
          } else
          if(value[0].employeeDetails.contactDetail) {
            let contactDetailData = {contactDetail: value[0].employeeDetails.contactDetail }; 
            this.session.set('contactDetail', contactDetailData); 
          }   
          if(this.session.get('educationDetails')){            
            this.session.set('educationDetails', this.session.get('educationDetails'));
          } else
          if(value[0].employeeDetails.educationDetail){
            let eduDetail = {educationDetail: value[0].employeeDetails.educationDetail};
            this.session.set('educationDetails',  eduDetail); 
          } 
          if(this.session.get('experienceDetails')){            
            this.session.set('experienceDetails', this.session.get('experienceDetails'));
          } else  
          if(value[0].employeeDetails.experienceDetail){
            let expDetail = { experienceDetail: value[0].employeeDetails.experienceDetail};
            this.session.set('experienceDetails', expDetail);
          }  
          if(this.session.get('confirmationDetail')){     
            //console.log('get', this.session.get('confirmationDetail'));       
            this.session.set('confirmationDetail', this.session.get('confirmationDetail'));
          } else
          if(value[0].employeeDetails.confirmationDetail){
            let confirmationDetailData = { confirmationDetail: value[0].employeeDetails.confirmationDetail};
            //console.log('confirmationDetailData',confirmationDetailData);
            this.session.set('confirmationDetail', confirmationDetailData); 
          } 
          
          if(this.session.get('formf')){            
            this.session.set('formf', this.session.get('formf'));
          } else
          if(value[0].employeeDetails.formf){
            let formfSet = {formf: value[0].employeeDetails.formf};
            this.session.set('formf', formfSet);
          }  
           
          if(this.session.get('insuranceDetail')){            
            this.session.set('insuranceDetail', this.session.get('insuranceDetail'));
          } else
          if(value[0].employeeDetails.insuranceDetail){
            let insuranceDetailSet = {insuranceDetail: value[0].employeeDetails.insuranceDetail};
            this.session.set('insuranceDetail', insuranceDetailSet);
          }  

          if(this.session.get('appDetail')){            
            this.session.set('appDetail', this.session.get('appDetail'));
          } else  
          if(value[0].employeeDetails.appDetail){
            let appdata = { appDetail: value[0].employeeDetails.appDetail  };  
            this.session.set('appDetail', appdata);
          }
        }
        
        //todo: implement like next
        if(value[0].employeeDetails.empPersonalData) {
          this.empdetailsData = value[0].employeeDetails.empPersonalData;
        }
      })
    } else {
      this.id = this.session.get('empId');
      if(this.session.get('employeeDetail')){            
        this.session.set('employeeDetail', this.session.get('employeeDetail'));
      }
      if(this.session.get('contactDetail')){            
        this.session.set('contactDetail', this.session.get('contactDetail'));
      }   
      if(this.session.get('educationDetails')){            
        this.session.set('educationDetails', this.session.get('educationDetails'));
      } 
      if(this.session.get('experienceDetails')){            
        this.session.set('experienceDetails', this.session.get('experienceDetails'));
      }
      if(this.session.get('confirmationDetail')){     
        //console.log('get', this.session.get('confirmationDetail'));       
        this.session.set('confirmationDetail', this.session.get('confirmationDetail'));
      } 
      
      if(this.session.get('formf')){            
        this.session.set('formf', this.session.get('formf'));
      } 
      if(this.session.get('insuranceDetail')){            
        this.session.set('insuranceDetail', this.session.get('insuranceDetail'));
      }  

      if(this.session.get('appDetail')){            
        this.session.set('appDetail', this.session.get('appDetail'));
      }
    }  
        // Upload mandatory check 
        // this.globalVar.uplodedZipPresent.subscribe(upflag => {console.log('sericeupflag', upflag);
        // if(upflag == false) {
        if(this.session.get('educationDetails')){
          for(var i=0; i<this.session.get('educationDetails').educationDetail.length+1; i++){
            
            if(this.session.get('educationDetails').educationDetail[i] ? (this.session.get('educationDetails').educationDetail[i].degree == '10th') && (this.session.get('educationDetails').educationDetail[i].eduType=='schools') : false){              
              this.tenthTrue = true;         
            }
            if(this.session.get('educationDetails').educationDetail[i] ? this.session.get('educationDetails').educationDetail[i].eduType == 'graduation' : false ){
              this.gradTrue = true;
            }

          }   
        }

      if(((!this.session.get('employeeDetail').empPersonalData) || (!this.session.get('employeeDetail').empPersonalData.DOB) 
          || (!this.session.get('employeeDetail').empPersonalData.PAN)) &&
          
      ((p.url == '/contactdetail')|| (p.url =='/educationdetail') || (p.url =='/experiencedetail') || (p.url ==  '/upload-document') || (p.url =='/confirmationdetail') || (p.url =='/insuranceform') || (p.url =='/form-f'))){
          // (!value[0].employeeDetails.empPersonalData)){
          //toast of filling mandatory field
          if (!this.session.get('employeeDetail').empPersonalData.DOB){        
            this.toastMessage=`<li>`+"Date of Birth"+ `</li>`;
          }
          if (!this.session.get('employeeDetail').empPersonalData.PAN){        
            this.toastMessage2=`<li>`+"PAN"+ `</li>`;
          }
          // if((!this.session.get('employeeDetail').empPersonalData.fName) || (!this.session.get('employeeDetail').empPersonalData.lName)) {
          //   this.toastMessage1= `<li>`+"Full Name."+ `</li>`;
          // }
          p.disableItem = true;
          const alert = await this.alertController.create({
            header: 'Mandatory Fields Empty!!',
            message: 'Please fill mandatory fields'+`<ul>`+ this.toastMessage2 +this.toastMessage1+this.toastMessage +`<ul>`,
            buttons: [
              {
                text: 'Ok',
                handler: () => { 
                  // this.router.navigate(['/home']);
                }
              }     
            ]
          });
          await alert.present();
        // }
       } else 
  if(((!contactData.permanentPhone) || (!contactData.permanentResidingSince) || 
        (!contactData.permanentAddressFlatNo) || (!contactData.permanentAddressStreet) || (!contactData.permanentAddressArea) || 
         (!contactData.permanentAddressDistrict) || (!contactData.permanentAddressState) || 
        (!contactData.emergencyMobile) || (!contactData.emergencyPersonName) || (!contactData.emailId)) && 
        ((p.url =='/educationdetail') || (p.url =='/experiencedetail') || (p.url ==  '/upload-document') || (p.url =='/confirmationdetail') || (p.url =='/insuranceform') || (p.url =='/form-f'))){ 
          if ((!contactData.permanentAddressFlatNo) || (!contactData.permanentAddressStreet) || (!contactData.permanentAddressArea) || 
          (!contactData.permanentAddressDistrict) || (!contactData.permanentAddressState)) {
            this.toastMessage = `<li>`+"Permanent Address"+ `</li>`;
          } 
          if (!contactData.emailId) {
            this.toastMessage1 = `<li>`+"Email Id";
          } 
          if (!contactData.permanentPhone) {
            this.toastMessage2 =  `<li>`+"Phone Number"+ `</li>`;
          } 
          if (!contactData.permanentResidingSince) {
            this.toastMessage3 = `<li>`+"Residing Since"+ `</li>`;
          } 
          if (!contactData.emergencyMobile) {
            this.toastMessage4 = `<li>`+"Emergency Mobile Number"+ `</li>`;
													 
          }
          if(!contactData.emergencyPersonName){
            this.toastMessage5 = `<li>`+"Emergency Contact Person Name"+ `</li>`;
          }
          const alert = await this.alertController.create({
            header: 'Mandatory Fields Empty!!',
            message: 'Please fill mandatory fields'+`<ul>
            ` +this.toastMessage + this.toastMessage1 + this.toastMessage2 + this.toastMessage3
             +this.toastMessage4 + this.toastMessage5 +`</ul>`,
            buttons: [
              {
                text: 'Ok',
                handler: () => { 
                  this.router.navigate(['/contactdetail']);
                }
              },    
            ]
          });
          await alert.present();
        }else
        if((this.session.get('educationDetails').educationDetail.length == 0 || this.tenthTrue == false  || this.gradTrue == false) && ((p.url =='/experiencedetail') || (p.url ==  '/upload-document') || (p.url =='/confirmationdetail') || (p.url =='/insuranceform') || (p.url =='/form-f'))) {
            const alert = await this.alertController.create({
              header: 'Mandatory Fields Empty!!',
              message: 'Please Fill and Save Education Details.',
              buttons: [
                {
                  text: 'Ok',
                  handler: () => { 
                    this.router.navigate(['/educationdetail']);
                  }
                }     
              ]
            });
            await alert.present();
        }        
        else if((this.session.get('insuranceDetail') ? (+Number(this.session.get('insuranceDetail').insuranceDetail.nomineeShare1) + +Number(this.session.get('insuranceDetail').insuranceDetail.nomineeShare2) + +Number(this.session.get('insuranceDetail').insuranceDetail.nomineeShare3) !== 100) : false)
        && ((p.url =='/confirmationdetail') || (p.url =='/form-f'))){
          const alert = await this.alertController.create({
            header: 'Info!',
            message: 'Sum of share payable should be 100%.',
            buttons: [
              {
                text: 'Ok',
                handler: () => {
                  this.router.navigate(['/insuranceform']);
                }
              }
            ]
          });
          await alert.present();
        }
        else if((this.session.get('formf') ? (+Number(this.session.get('formf').formf.nomineeShare1) + +Number(this.session.get('formf').formf.nomineeShare2) + +Number(this.session.get('formf').formf.nomineeShare3) !== 100) : false)
         && (p.url =='/confirmationdetail')){
          const alert = await this.alertController.create({
            header: 'Info!',
            message: 'Sum of share payable should be 100%.',
            buttons: [
              {
                text: 'Ok',
                handler: () => {
                  this.router.navigate(['/form-f']);
                }
              }
            ]
          });
          await alert.present();

        } else if((p.url =='/confirmationdetail') || (p.url =='/insuranceform') || (p.url =='/form-f')){
          if(this.data.getDataByID()){
            this.data.getDataByID().subscribe(data=> { 
              let value= data.json();
              this.id = value[0]._id;
              this.data.readUploadData(this.id).subscribe(data => {            
                if(data) {
                  data.forEach(async element => {
                    if((element.match(/.zip/g) == ".zip" || element.match(/.ZIP/g) == ".ZIP" || element.match(/.rar/g) == ".rar" || element.match(/.RAR/g) == ".RAR") 
                    || (element.includes(".pdf") || element.includes(".PDF")) && (element.includes('GeneralUp-'))
                    || (element.includes(".pdf") || element.includes(".PDF")) && (element.includes('ExperienceUp-'))
                    || (element.includes(".pdf") || element.includes(".PDF")) && (element.includes('EducationUp-'))
                    || (element.includes(".pdf") || element.includes(".PDF")) && (element.includes('PAN-'))){   
                      
                      this.upfilename.push(element);          
                      this.globalVar.changeUplodedZipPresent(true);
                    }else {
                      // console.log('mandat upload', this.upfilename);     
                    
                    }
                  }); 
                }
                
              this.globalVar.uplodedZipPresent.subscribe(async upflag => {
              if(upflag == false || (this.upfilename.length ==0)) {
                const alert = await this.alertController.create({
                  header: 'Info!',
                  message: 'Please Upload the Certificates.',
                  buttons: [
                    {
                      text: 'Ok',
                      handler: () => { 
                        this.router.navigate(['/upload-document']);
                      }
                    },    
                  ]
                });
                await alert.present();
              
                } else {
                  this.urlNavigation(p);
                }     
              });
              });
            });
          } 
        }  else {
          this.urlNavigation(p);
        }       
    }
    if(this.hrData.getHrFlag()){
      this.hrData.changeIsHr(true);      
      this.hrData.empDataSelected.subscribe(selecteddata => { 
        // console.log(JSON.parse(JSON.stringify(selecteddata)));
        this.selectedHrData = JSON.parse(JSON.stringify(selecteddata)); 
        if (this.selectedHrData){
          this.session.set('empDataByHR', this.selectedHrData.empDataByHR); 
          this.id = this.selectedHrData._id; 
          this.data.setEmpID(this.id);  
    // if(this.hrData.getHrFlag()){
    //   this.hrData.empDataSelected.subscribe(selecteddata => {  
    //     this.selectedHrData = JSON.parse(JSON.stringify(selecteddata)); 
    
          if(this.session.get('employeeDetail')){            
            this.session.set('employeeDetail', this.session.get('employeeDetail'));
          } else
          if(this.selectedHrData.employeeDetails.empPersonalData) {
            let employees = { empPersonalData: this.selectedHrData.employeeDetails.empPersonalData} 
            this.session.set('employeeDetail', employees);
          }
          if(this.session.get('contactDetail')){            
            this.session.set('contactDetail', this.session.get('contactDetail'));
          } else
          if(this.selectedHrData.employeeDetails.contactDetail) {
              let contactDetailData = {contactDetail: this.selectedHrData.employeeDetails.contactDetail }; 
              this.session.set('contactDetail', contactDetailData); 
            } 
          if(this.session.get('educationDetails')){            
            this.session.set('educationDetails', this.session.get('educationDetails'));
          } else 
          if(this.selectedHrData.employeeDetails.educationDetail){
            let eduDetail = {educationDetail: this.selectedHrData.employeeDetails.educationDetail};
            this.session.set('educationDetails',  eduDetail); 
          }   
          if(this.session.get('experienceDetails')){        
            this.session.set('experienceDetails', this.session.get('experienceDetails'));
          } else
          if(this.selectedHrData.employeeDetails.experienceDetail){
            let expDetail = { experienceDetail: this.selectedHrData.employeeDetails.experienceDetail};
            this.session.set('experienceDetails', expDetail);
          }  
          if(this.session.get('confirmationDetail')){      
            this.session.set('confirmationDetail', this.session.get('confirmationDetail'));
          } else
          if(this.selectedHrData.employeeDetails.confirmationDetail){
            let confirmationDetailData = { confirmationDetail: this.selectedHrData.employeeDetails.confirmationDetail };
            this.session.set('confirmationDetail', confirmationDetailData); 
          }           
          
          if(this.session.get('formf')){            
            this.session.set('formf', this.session.get('formf'));
          } else
          if(this.selectedHrData.employeeDetails.formf){
            let formfSet = {formf: this.selectedHrData.employeeDetails.formf};
            this.session.set('formf', formfSet);
          }  
          
          if(this.session.get('insuranceDetail')){            
            this.session.set('insuranceDetail', this.session.get('insuranceDetail'));
          } else 
          if(this.selectedHrData.employeeDetails.insuranceDetail){
            let insuranceDetailSet = {insuranceDetail: this.selectedHrData.employeeDetails.insuranceDetail};
            this.session.set('insuranceDetail', insuranceDetailSet);
          } 

          if(this.session.get('appDetail')){            
            this.session.set('appDetail', this.session.get('appDetail'));
          } else  
          if(this.selectedHrData.employeeDetails.appDetail){
            let appdata = { appDetail: this.selectedHrData.employeeDetails.appDetail  };  
            this.session.set('appDetail', appdata);
          }

        }
      });
	  this.urlNavigation(p);					
    }
  }
  urlNavigation(p){
    if (p.url == '/welcome/:email') this.router.navigate(['/welcome/'+this.email]);;
    if (p.url == '/home') this.router.navigate(['/home']);
    if (p.url == '/contactdetail') this.router.navigate(['/contactdetail']);
    if(p.url =='/educationdetail') this.router.navigate(['/educationdetail']);
    if(p.url =='/experiencedetail') this.router.navigate(['/experiencedetail']); 
    if(p.url ==  '/upload-document') this.router.navigate(['/upload-document']);
    if(p.url =='/confirmationdetail') this.router.navigate(['/confirmationdetail']);
    if(p.url =='/insuranceform') this.router.navigate(['/insuranceform']);
    if(p.url =='/form-f') this.router.navigate(['/form-f']);
    if(p.url =='/declaration-letter') this.router.navigate(['/declaration-letter']);
  }
  
  getEmpdataById(email: string) {
    this.data.getLoginData(email).subscribe(data => {
      let jsonData =  data.json();
    });
  }

  menuChange(e){
  }

  showMenuClick(event, p) {  
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  async logOut() {
    const alert = await this.alertController.create({
      header: 'Logout',
      message: 'Are you sure you want to Logout.',
      buttons: [
        {
          text: 'Ok',
          handler: () => { 
            window.sessionStorage.clear();
            this.hrData.setReadFlag(false);    
            localStorage.setItem('authUser', 'noUser'); 
            window.localStorage.clear();    
            this.hrData.setHrFlag(false);
            this.router.navigate(['/login']);
          }
        },
        {
          text: 'Cancel',
          handler: () => {}
        }

      ]
    });
    await alert.present();
  }
}
