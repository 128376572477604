import { NgModule } from '@angular/core';
import { ActivatedRoute ,Routes, RouterModule, Router, RouteConfigLoadStart } from '@angular/router';
import {HrDataService} from './hr-data.service';
import 'rxjs/add/operator/filter';

import { filter } from 'rxjs/operators';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule'
  },
  {
    path: 'add',
    loadChildren: './thankyou/add.module#AddPageModule'
  },
  { 
    path: 'add-employee', 
    loadChildren: './add-employee/add-employee.module#AddEmployeePageModule'
  },
  {
    path: 'candidatelist', 
    loadChildren: './candidatelist/candidatelist.module#DetailsPageModule' 
  },
  { 
    path: 'login', 
    loadChildren: './login/login.module#LoginPageModule' 
  },
  { 
    path: 'welcome/:email', 
    loadChildren: './welcome/welcome.module#WelcomePageModule' 
  },
  { 
    path: 'contactdetail', 
    loadChildren: './contactdetail/contactdetail.module#ContactdetailPageModule'
  },
  { 
    path: 'educationdetail', 
    loadChildren: './educationdetail/educationdetail.module#EducationdetailPageModule'
  },
  { 
    path: 'experiencedetail', 
    loadChildren: './experiencedetail/experiencedetail.module#ExperiencedetailPageModule' 
  },
  { 
    path: 'confirmationdetail', 
    loadChildren: './confirmationdetail/confirmationdetail.module#ConfirmationdetailPageModule' 
  },
  {
    path: 'upload-document', 
    loadChildren: './upload-document/upload-document.module#UploadDocumentPageModule' 
  },
  { path: 'admin-user', loadChildren: './admin-user/admin-user.module#AdminUserPageModule' },
  { path: 'advance-search', loadChildren: './admin-user/advance-search/advance-search.module#AdvanceSearchPageModule' },
  { path: 'map', loadChildren: './welcome/map/map.module#MapPageModule' },
  { path: 'insuranceform', loadChildren: './insuranceform/insuranceform.module#InsuranceformPageModule' },
  { path: 'form-f', loadChildren: './form-f/form-f.module#FormFPageModule' },
  { path: 'declaration-letter', loadChildren: './declaration-letter/declaration-letter.module#DeclarationLetterPageModule' },
  { path: 'modal-rating', loadChildren: './modal-rating/modal-rating.module#ModalRatingPageModule' },
  { path: 'candidate-logout', loadChildren: './candidate-logout/candidate-logout.module#CandidateLogoutPageModule' }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: location.search.startsWith( "?always" )
                        ? "always"
                        : "emptyOnly"
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  authUser:string;
  isHrFlag :boolean;
  constructor(private hrSerData: HrDataService,private router: Router,private activatedRoute : ActivatedRoute) { 
  // if(this.router. ){
  //   path: 'welcome/:email'
  // }
    
    // this.activatedRoute.url.subscribe(url =>{
    // });
    // window.onbeforeunload = function(e) {
    //   console.log('refresh');
      
    //   return 'Dialog text here.';
    // };

    // router.events.pipe(
    //   filter(event => event instanceof RouteConfigLoadStart)  
    // ).subscribe((event: RouteConfigLoadStart) => {
    //   setTimeout(() => {      
    //   if((event.route.path == "welcome/:email") || (this.authUser!=='authorizedUser')|| (localStorage.getItem('authUser') !=='authorizedUser') || (event.route.path == "candidatelist")){  
    //     if(event.route.path == "welcome/:email"  || (event.route.path == "candidatelist")){ 
    //       this.hrSerData.setHrFlag(false);         
    //       this.hrSerData.changeAuthUser('authorizedUser');
    //     } else {
    //       this.hrSerData.setHrFlag(false);         
    //       this.hrSerData.changeAuthUser('authorizedUser');      
    //     }
    // //     localStorage.setItem('authUser', 'authorizedUser');
    // //     console.log(localStorage.getItem('authUser'));
    // // this.hrSerData.changeAuthUser('authorizedUser');
    // //     this.hrSerData.currentAuthUser.subscribe(user => this.authUser = user);
    // //     this.hrSerData.isHrFlag.subscribe(flag => {
    // //       console.log('flag', flag);
    // //       this.isHrFlag = flag});
    //       window.onbeforeunload = function(e) {
    //         console.log('refresh 1');
            
    //         return alert("Please login through the register URL.");  
    //       };
    //     console.log(this.hrSerData.getHrFlag());
    //     if((this.authUser!=='authorizedUser') && (localStorage.getItem('authUser') !=='authorizedUser')){
    //       if(this.isHrFlag ==false){
    //         console.log('candidate');
    //         this.router.navigate(['/welcome/:email']);
    //       }else if(this.isHrFlag ==true){
    //         this.router.navigate(['/login']);
    //       } 
    //     }
    //   }
    // },500);
    // });

  }
  ngOnInit() {
    this.router.events
      .subscribe((event) => {
        // example: NavigationStart, RoutesRecognized, NavigationEnd
      });
  }
}
