//const constUrl = 'http://Dayoneformservices-env.z3cj7jyivk.us-east-2.elasticbeanstalk.com';
// const constUrl = 'http://Aloha.us-east-2.elasticbeanstalk.com';
// const constUrl = 'http://dofa.us-east-2.elasticbeanstalk.com';
const constUrl = 'https://alohahronboarding.pro';
// const constUrl = 'http://Alohatest-env.eba-px2qwcd3.us-east-2.elasticbeanstalk.com';
// const constUrl = 'http://Dofaops.us-east-2.elasticbeanstalk.com';
// const constUrl = 'http://localhost:4444';
// const constUrl = 'http://10.2.44.164:4444';
//Prod url :http://Dayoneformservices-env.z3cj7jyivk.us-east-2.elasticbeanstalk.com

export class AppSettings {
    public static POST_LOGINDATA = constUrl+ '/api/validateLogin';
    public static POST_EMP_DATA = constUrl+ '/api/onboards';
    public static PUT_EMP_DATA = constUrl+ '/api/onboards';
    public static GET_EMP_DATA = constUrl+ '/api/allonboard';
    public static GET_SELECTED_EMP_DATA= constUrl+ '/api/onboarded';
    public static GET_DATA_BYDOJ = constUrl+ '/api/getByCandidateDoJ';
    public static DELETE_DATA = constUrl+ '/api/deleteOnboards';
    public static ADVANCE_SEARCH = constUrl+ '/api/advancedSearch';
    public static LOGIN_EMP = constUrl+ '/api/getDataByLogin';
    public static HRDATA_BYLOC=constUrl+ '/api/getMapDataByLoc';
    public static SEARCH_BYSATUS=constUrl+ '/api/getDataByStatus';
	public static GET_STATES = constUrl+ '/api/states';	
    public static UPLOADCERTIFICATE_URL = constUrl+ '/api1/api';
    public static POST_DEL_UPLOAD = constUrl+ '/api1/deleteFile';
    public static REGENERATE_JWT_TOKEN = constUrl+ '/api/regenerateJwtToken';
	public static GET_UPLOADDATA = constUrl+ '/api1/readFiles';
    public static PDF_DOWNLOAD =constUrl+ '/api2/pdfDown';
    public static GET_ZIPFOLDER = constUrl+ '/api1/zippie';
    public static PDF_GENERATION = constUrl+ '/api2/pdfsync1';
    public static POST_SMTP = constUrl+ '/api3/SMTPMail';
    public static GET_EXCEL_DOWN = constUrl+ '/api5/excelDown';
    public static GET_EXCEL_DOWNLOAD = constUrl+ '/api5/excelDownNew';
    // public static POST_SMTP='http://10.2.108.65:8000/mailTrigr';
    // public static POST_STMP_HR = 'http://10.2.108.65:8000/mailHRTrigr';
    // public static POST_RESEND_MAIL = 'http://10.2.108.65:8000/mailRevert';
        
}