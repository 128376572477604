import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { AlertController } from '@ionic/angular';
import { SessionStorageService } from 'angular-web-storage';
import { DataService } from '../data.service';
import { GlobalVariableService } from '../global-variable.service';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-rating',
  templateUrl: './modal-rating.page.html',
  styleUrls: ['./modal-rating.page.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ModalRatingPage implements OnInit {
  @Input() rating: number = 0;
  @Input() itemId: number;
  @Input('starCount') private starCount: number = 0;
  @Output() ratingClick: EventEmitter<any> = new EventEmitter<any>();

  inputName: string;
  feedbackComment: string;
  appdata: any;
  status: string;
  id: string;
  doneRatingFlag: boolean =false;
  constructor(public globalVar : GlobalVariableService, public router: Router, private empdata: DataService, private snackBar: MatSnackBar, public alertController: AlertController, public session: SessionStorageService, public modalController: ModalController) { 
    this.globalVar.currentEmpDbId.subscribe(empid => this.id = empid);
    console.log('Id :: ' + this.id + this.id.length);
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
        history.go(1);   
    };
  }

  ngOnInit() {
    this.inputName = this.itemId + '_rating';
    if(this.id == undefined || this.id == '' || this.id == null || this.id.length < 3) {
      this.modalController.dismiss();
    }
  }

  onClick(rating: number): void {
    this.rating = rating;
    this.ratingClick.emit({
      itemId: this.itemId,
      rating: rating
    });
  }

  async submitFeedback(){
    if(this.rating==0){
      const alert = await this.alertController.create({
        header: 'Info!',
        message: 'Please select your Rating for our Onbaording app.',
        buttons: [ {
            text: 'Ok',
            handler: () => {
            }
          }
        ]
      });
      await alert.present();
    } 
    else if(!this.session.get('employeeDetail') && (!this.session.get('contactDetail')) && (!this.session.get('empDataByHR'))){
      this.doneRatingFlag =true;
      const alert = await this.alertController.create({
        header: 'Info!',
        message: 'You have already submitted your feedback.',
        buttons: [ {
            text: 'Ok',
            handler: () => {
              window.sessionStorage.clear();
            }
          }
        ]
      });
      await alert.present();
    }else {
      this.appdata = { 
        appDetail: {
          rating: this.rating || '',
          feedbackComment: this.feedbackComment || ''
        }
      };    
      // Save page data in session and Navigate to next page
      this.session.set('appDetail', this.appdata);
      this.status = 'Completed'; 
      // this.hrData.setCandidateID(this.id);             
      this.empdata.setDataStatus(this.status);  
      //Deepika.Bharti@scientificgames.com,Lakshmikanth.Srinivas@scientificgames.com,Adyashakti.Patnaik@scientificgames.com
      this.id = this.session.get('empId');
      this.empdata.editData(this.id).subscribe(async () => {
        
        const alert = await this.alertController.create({
          header: 'Info!',
          message: 'Thank you for your feedback.',
          buttons: [ {
              text: 'Ok',
              handler: () => {
                this.doneRatingFlag =true;
                window.sessionStorage.clear();
                this.router.navigate(['/add']);
                // window.close();
                this.modalController.dismiss();
              }
            }
          ]
        });
        await alert.present();
      } )
    }
  }

}
