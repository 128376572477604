import { Injectable, Directive  } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from './appSetting.config';
import { Http, Headers, RequestOptions, ResponseContentType } from '@angular/http';
// import { DataService } from './data.service';
import { SessionStorageService } from 'angular-web-storage';
import { Contact } from './contact';
import * as moment from 'moment';
import { delay } from 'rxjs/operators';
import { AlertController, ToastController } from '@ionic/angular';
import { async } from '@angular/core/testing';

@Injectable()
export class HrDataService {
  private flagValue : boolean;
  private readFlagValue :  boolean;
  jsonData : any;
  loc: any;
  doj: any;
  dFrom: any;
  dTo: any;
  emailSMTPData: any;
  smtpHrData: any;

  //TODO: static data service
  public selectedData = new BehaviorSubject([]);
  empDataSelected = this.selectedData.asObservable();

  private viewEmpData = new BehaviorSubject('Default');
  currentMessage = this.viewEmpData.asObservable();

  private authUser = new BehaviorSubject('noUser');
  currentAuthUser = this.authUser.asObservable();

  public completeEmpData = new BehaviorSubject({});
  AllEmpData = this.completeEmpData.asObservable();

  public isHr = new BehaviorSubject(false);
  isHrFlag = this.isHr.asObservable();
  
  constructor( private httpClient: HttpClient, public http: Http, public session: SessionStorageService, private toastCtrl: ToastController, private alertController: AlertController) { }

  changeMessage(message: string) {
    this.viewEmpData.next(message);
  }
  public setHrFlag(value){
    this.flagValue = value;
  }

  public getHrFlag(){
    return this.flagValue;
  }

  changeIsHr(value: boolean) {
    this.isHr.next(value);
  }

  public setReadFlag(value){
    this.readFlagValue = value;
  }

  public getReadFlag(){
    return this.readFlagValue;
  }

  // Get HR data
  getHrData() {
    // return this.http.put(AppSettings.PUT_EMP_DATA, );  
  }

  getDataByDOJ() {
    let DOJ = moment(new Date()).format('YYYY-MM-DD');
    return this.httpClient.get(AppSettings.GET_DATA_BYDOJ + '?doj=' + DOJ);   
  }
  
  // setHrdata() {
  //   let canditateEmpCode = 108790;
  //   this.jsonData =  Object.assign(this.session.get('empDataByHR'));
  //   console.log(this.jsonData);
  //   if((this.jsonData !== undefined) || (this.jsonData !== null)){         
  //     const headers = new Headers({ 'Content-Type': 'application/json' });
  //     const options = new RequestOptions({ headers: headers });
      
  //     this.http.post(AppSettings.POST_EMP_DATA, this.jsonData, options).subscribe(  
  //       data => {
  //         console.log(data);        
  //       },
  //       error => console.log('oops', error)
  //     );
  //   }
  //   else { 
  //     console.log('new');
  //   }    
  //   this.getHrData();
  // }   

  loginToAdmin(username, password) {
    const headers = new Headers({ 'Content-Type': 'application/json' });
    const options = new RequestOptions({ headers: headers });
    let logingData ={
      'user' : username,
      'pass' : password
    }
    return this.http.post(AppSettings.POST_LOGINDATA, logingData, options);    
  }

  changeAuthUser(user: string) {
    this.authUser.next(user);    
    localStorage.setItem('authUser', 'authorizedUser');
  }

  deleteRecord(recId){
    return this.httpClient.delete(AppSettings.DELETE_DATA+'?id=' + recId);
  }

  advanceSearch(loc, doj , dFrom , dTo){ 
    if(loc=='all'){
      this.loc = '?aloc=all';
    }else {      
      this.loc = '?loc=' + loc;      
    } 
    this.doj = '&doj=' + doj;
    this.dFrom = '&dFrom=' + dFrom;
    this.dTo = '&dTo=' + dTo;
    
    // let searchRes = this.http.get(AppSettings.ADVANCE_SEARCH + this.loc);
    // TODO: search on dates
    if(((doj == undefined || doj == null) && (dFrom == undefined || dTo == undefined))) { 
        return this.httpClient.get(AppSettings.ADVANCE_SEARCH + this.loc);
    }
    if(dFrom == undefined && dTo == undefined) {
      return this.httpClient.get(AppSettings.ADVANCE_SEARCH + this.loc + this.doj);
    }
    if(doj == undefined || doj == null) {
      return this.httpClient.get(AppSettings.ADVANCE_SEARCH + this.loc + this.dFrom +  this.dTo);
    }
  }

  //TODO: static data service
  setSelectedData(data) {
    this.selectedData.next(data);
  }

  public getEmpData() {       
    // console.log('nowget', this.http.get(AppSettings.PUT_EMP_DATA));

  //  return this.http.put(AppSettings.PUT_EMP_DATA);
  }

  statusSearch(status) {
    return this.httpClient.get(AppSettings.SEARCH_BYSATUS + '?status=' + status);
  }

  // generatePDF(data){
  //   const headers = new Headers({ 'Content-Type': 'application/json' });
  //   const options = new RequestOptions({ headers: headers });
  //   let genData = [data]; 
  //   console.log('gen data', genData);
  //   this.http.post(AppSettings.PDF_GENERATION, genData).subscribe(data => 
  //     {        
  //       console.log('gendata after', data.text() );
  //       let filePath =data.text();
  //       this.http.get(AppSettings.PDF_DOWNLOAD+'?fName='+ filePath).subscribe(data =>
  //         {            
  //       console.log(data );        
  //   window.open(data.url);
  //         });
  //     });
  // }
  
  //Send data to generate PDF
  sendDataToPDF(data){
    const headers = new Headers({ 'Content-Type': 'application/json' });
    const options = new RequestOptions({ headers: headers });
    let genData = [data]; 
    
    return new Promise(resolve => {
      setTimeout(() => {
        this.httpClient.post(AppSettings.PDF_GENERATION, genData).pipe(delay(500)).subscribe(data => {
        resolve(data);
          // this.filePath =data.text();   
          }, 
          async err=> {        
            const toast = await this.toastCtrl.create({
              message: 'Error: Print failed. Please try after sometime.',
              color: 'primary',
              showCloseButton: true,
              position: 'top',
              cssClass: "className",
              duration: 2000
            });
            this.loading = false;
            toast.present(); 
          });  
        }, 2000);
      });      
  }
  loading :boolean = true;
  //Print PDF
  async generatePDF(data){   
     this.sendDataToPDF(data).then((value:any) =>{
       let valueObject = JSON.parse(value);
       let filename;
       if(valueObject && valueObject.length > 0) {
         filename =  valueObject[0];
       }
      new Promise(resolve => {
        this.httpClient.get(AppSettings.PDF_DOWNLOAD+'?fName='+ filename,{
          // responseType: ResponseContentType.Blob
          responseType: 'blob'
        }).subscribe(
          (response) => { // download file
            var blob = new Blob([response], {type: 'application/pdf'});
            const blobUrl = URL.createObjectURL(blob);
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = blobUrl;
            document.body.appendChild(iframe);
            iframe.contentWindow.print();
            resolve(this.loading);
            this.loading = false;
        }, 
        async err=> {        
          const toast = await this.toastCtrl.create({
            message: 'Error: Print failed. Please try after sometime.',
            color: 'primary',
            showCloseButton: true,
            position: 'top',
            cssClass: "className",
            duration: 2000
          });
          this.loading = false;
          toast.present(); 
        })
      });    
     }) 
  }
  public loadingProgress(){
    return this.loading = false;
  }

  downloadZip(fulldata){
    // this.sendDataToPDF(fulldata).then(value =>{
      return this.httpClient.get(AppSettings.GET_ZIPFOLDER + '?fName=' + fulldata._id).subscribe(data => {
        this.httpClient.get(AppSettings.PDF_DOWNLOAD+'?fName='+ data, { observe: 'response' }).subscribe(data =>{    
            window.open(data.url);
            this.loading = false;
        }, 
        async error=> {        
          const toast = await this.toastCtrl.create({
            message: 'Error: Download failed. Please try after sometime.',
            color: 'primary',
            showCloseButton: true,
            position: 'top',
            cssClass: "className",
            duration: 2000
          });
          toast.present(); 
        })
      }), 
      async err=> {        
        const toast = await this.toastCtrl.create({
          message: 'Error: Download failed. Please try after sometime.',
          color: 'primary',
          showCloseButton: true,
          position: 'top',
          cssClass: "className",
          duration: 2000
        });
        toast.present(); 
      };
    // })
  }

  getDownloadReport() {
    this.loading = true;
    this.httpClient.get(AppSettings.GET_EXCEL_DOWNLOAD, { observe: 'response' }).subscribe(
      async (data)=>{    
        console.log("Download Status : " + data.status);
        let reportStatus = data.status;
        if (reportStatus != null && reportStatus != undefined && reportStatus == 313) {
          const alert = await this.alertController.create({
            header: 'Error!',
            message: 'ALOHA Report Download Failed or the File does not Exists. Please check with the ADMIN.',
            buttons: [
              {
                text: 'Ok',
                handler: () => {               
                  this.loading = false;
                }
              }
            ]
          });  
          await alert.present();        
        } else {
          this.loading=false;
          window.open(data.url);                
        }
      },
      async error => {
        setTimeout(async() => {
              const toast = await this.toastCtrl.create({
                message: 'Error: Report Download failed. Please try after sometime.',
                color: 'primary',
                showCloseButton: true,
                position: 'top',
                cssClass: "className",
                duration: 2000
              });
              this.loading = false;
              toast.present(); 
            }, 2000);  
        })
  }

  getFullReportDown() {
    this.loading = true;
    this.httpClient.get(AppSettings.GET_EXCEL_DOWN).subscribe(
      async (data)=>{
        console.log("Report Status : " + data);
        let reportStatus = data;
        if (reportStatus != null && reportStatus != undefined && reportStatus == "Success") {
          const alert = await this.alertController.create({
            header: 'Success!',
            message: 'ALOHA Full Report Generation has been successfully completed.! Please click the Download Report button to download the report.',
            buttons: [
              {
                text: 'Ok',
                handler: () => {               
                    this.loading = false;
                    // this.saved = true;    
                    // location.reload();
                    // this.router.navigate(['/confirmationdetail']).then(()=> location.reload());
                }
              }
            ]
          });
          await alert.present();
        } else {
          const alert = await this.alertController.create({
            header: 'Error!',
            message: 'ALOHA Full Report Generation Failed.! Please try again after some time.',
            buttons: [
              {
                text: 'Ok',
                handler: () => {               
                  this.loading = false;
                }
              }
            ]
          });
        }
      },
      async error => {
        setTimeout(async() => {
              const toast = await this.toastCtrl.create({
                message: 'Error: Print failed. Please try after sometime.',
                color: 'primary',
                showCloseButton: true,
                position: 'top',
                cssClass: "className",
                duration: 2000
              });
              this.loading = false;
              toast.present(); 
            }, 2000);  
          }
      // }
      // window.open(data.url);
    );
  }
  emailSMTP(emailId, encryptMailId, empFName, empMName, empLName, resendComment, workLocation, DOJFormate, subject, template) {  
    this.emailSMTPData = {
      "emailId": emailId,
      "encEmail": encryptMailId,
      "empName": empFName.charAt(0).toUpperCase() + empFName.slice(1) + ' '+ (empMName ? (empMName + ' ') : '') + empLName,
      "hrComments": resendComment,
      "empLoc": workLocation,
      "empDOJ": DOJFormate,
      "subject": subject,
      "template": template
    };
    // if(template == "Hr") {
    //   console.log('in temp', template);
    //   this.emailSMTPData = {
    //     "emailId": "payal.haldar@scientificgames.com",
    //     "empDOJ": "2019-08-20",
    //     "empLoc": "Chennai",
    //     "empName": "Qwertt wwertt",
    //     "encEmail": "",
    //     "hrComments": "",
    //     "subject": "Onboarding Details Submitted For Review",
    //     "template": "Hr"
    //     }
    // }    
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    // const options = new RequestOptions({ headers: headers });
    //10.2.108.65:8080/mailTrigger1/ old get
    return this.httpClient.post(AppSettings.POST_SMTP, this.emailSMTPData, {headers});
    // return this.http.post(AppSettings.POST_SMTP, this.emailSMTPData, options);
  }

  // resendSMTP(emailId, encryptMailId, empFName, empMName, empLName, resendComment, workLocation, DOJFormate, subject, template) { 
  //   console.log(emailId, encryptMailId, empFName, empMName, empLName, resendComment, workLocation, DOJFormate, subject, template);  
  //   this.emailSMTPData = {
  //     "emailId": emailId,
  //     "encEmail": encryptMailId,
  //     "empName": empFName.charAt(0).toUpperCase() + empFName.slice(1) + ' '+ (empMName ? (empMName + ' ') : '') + empLName,
  //     "hrComments": resendComment,
  //     "empDOJ": '',
  //     "empLoc": '',
  //     "subject": "Scientific Games - Onboarding Process",
  //     "template": "Revert"
  //   };
  //   console.log('emailId', this.emailSMTPData)
    
  //   const headers = new Headers({ 'Content-Type': 'application/json' });
  //   const options = new RequestOptions({ headers: headers });
  //   //10.2.108.65:8080/mailTrigger1/ old get
  //   return this.http.post(AppSettings.POST_RESEND_MAIL, this.emailSMTPData, options);
  // }

  // sentMailToHr(emailId, encryptMailId, empFName, empMName, empLName, resendComment, workLocation, DOJFormate, subject, template){
  //   this.smtpHrData = {
  //     "emailId": "payal.haldar@scientificgames.com",
  //     "empDOJ": "2019-08-20",
  //     "empLoc": "Chennai",
  //     "empName": "Qwert wwert",
  //     "encEmail": "",
  //     "hrComments": "",
  //     "subject": "Onboarding Details Submitted For Review",
  //     "template": "Hr"
  //   }
  //   const headers = new Headers({ 'Content-Type': 'application/json' });
  //   const options = new RequestOptions({ headers: headers });
  //   //10.2.108.65:8080/mailTrigger1/ old get
  //   return this.http.post(AppSettings.POST_SMTP, this.smtpHrData, options);  
  // }

}