import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { SessionStorageService } from 'angular-web-storage';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';

@Injectable()
export class GlobalVariableService {
    // readonly myConfigValue:string = 'abc'; U2FsdGVkX19VdqBoqmfWsA1HEUaDLA4coEKOYXVs7aBRDv4f5bYscdsD5QP4naD3pFlHgd
    private filePresent = new BehaviorSubject(false);
    uplodedZipPresent = this.filePresent.asObservable();

    maxDate = new Date();
    private DOJ = new BehaviorSubject(this.maxDate);
    currentDOJ = this.DOJ.asObservable();

    private joiningLocation = new BehaviorSubject('Chennai/Bangalore/Pune');
    currentJoiningLocation = this.joiningLocation.asObservable();

    private empDbId = new BehaviorSubject('id');
    currentEmpDbId = this.empDbId.asObservable();
    
    private nomineeAll = new BehaviorSubject([]);
    currentNominee = this.nomineeAll.asObservable();

    constructor() { 
    }

    changeUplodedZipPresent(upFlag: boolean) {
        // console.log('upFlag', upFlag);
        this.filePresent.next(upFlag);
    }

    changeDOJ(doj: Date) {
        // console.log('upFlag', doj);
        this.DOJ.next(doj);
    }

    changeLocation(location: string) {
        this.joiningLocation.next(location);
    }

    changeEmpDbId(id: string) {
      
        this.empDbId.next(id);
    }

    changeNominee([dependantName1, dependantName2, dependantName3, dependantName4, dependant1DobDate, dependant2DobDate, dependant3DobDate,dependant4DobDate,
        dependantRelationship1, dependantRelationship2, dependantRelationship3, dependantRelationship4, nomineeAddress1, nomineeAddress2, nomineeAddress3, 
        nomineeShare1, nomineeShare2, nomineeShare3]: any){
            this.nomineeAll.next([dependantName1, dependantName2, dependantName3, dependantName4, dependant1DobDate, dependant2DobDate, dependant3DobDate,dependant4DobDate,
                dependantRelationship1, dependantRelationship2, dependantRelationship3, dependantRelationship4, nomineeAddress1, nomineeAddress2, nomineeAddress3, 
                nomineeShare1, nomineeShare2, nomineeShare3]);
    }

}
